<template>
  <div>
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="search">
      <el-input placeholder="请输入要查询的手机号"></el-input>
      <el-date-picker v-model="value1" type="date" placeholder="选择日期">
      </el-date-picker>
      <div>
        <el-button type="primary">搜索</el-button>
        <el-button type="info">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%" height="450">
        <el-table-column label="序号" type="index" width="80" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="车主" width="180">
        </el-table-column>
        <el-table-column prop="boxId" label="行程ID" width="180">
        </el-table-column>
        <el-table-column prop="date" label="出发时间"> </el-table-column>
        <el-table-column prop="endDate" label="起始点"> </el-table-column>
        <el-table-column prop="endXY" label="订单数"> </el-table-column>
        <el-table-column prop="endXY" label="乘客信息"> </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength > 0"
        :total="pageLength"
        :page.sync="pageIndex"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  data() {
    return {
      value1: "",
      tableData: [],
      state: "",
      BreadcrumbCon: [
        {
          name: "行程管理",
        },
        {
          name: "手机号",
        },
      ], // 面包屑数据

      // 分页数据
      pageLength: 20,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageIndex: 1, //要传过去的数据 第几页
    };
  },

  methods: {
    handlePageChange(data) {
      this.pageIndex = data.pageIndex;
      this.pageSize = data.pageSize;
      // this.loadData(); //这是重新获取数据的方法
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  .el-input {
    width: 300px;
    margin-right: 10px;
  }
}
</style>